/*let App = () => {
  return `<div id="children">
            <span>PROVA</span>
            </div>`;
};

document.getElementById("app").innerHTML = App();
*/
console.log("ciao");
window.alert("The web site is under costruction");

function downloadCurriculum() {
  download_file(
    "https://drive.google.com/file/d/1_iNnPNCpwUsMpuCVx7W9uK3WYwBiO5QV/view?usp=sharing",
    "cv-amedei.pdf"
  );
}

window.onload = init;

function init() {
  document.getElementById("mycurriculum").addEventListener("click", () => {
    downloadCurriculum();
    console.log("pressed");
  });
}

function download_file(fileURL, fileName) {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_blank";
    var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
}
